"use client";

import { Button, Center, Stack } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import { useRouter } from "next/navigation";
import { FC } from "react";
import { EmptyStateMessage } from "~/lib/components/EmptyStateMessage";

const RootNotFound: FC = () => {
  const router = useRouter();

  return (
    <Center h="100dvh">
      <Stack gap="xl" align="center" mt={{ base: "-10em", md: 0 }}>
        <EmptyStateMessage image="48" message="Aradığınız sayfa bulunamadı" />

        <Button leftSection={<IconChevronLeft />} onClick={() => router.back()}>
          Geri Dön
        </Button>
      </Stack>
    </Center>
  );
};

export default RootNotFound;
